// REACT
import React from "react"
// MUI
import { Link, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageBlock } from "../components/imageBlock"
import { ImageWithCaption } from "../components/imageWithCaption"

// STYLES
const useStyles = makeStyles(theme => ({
  streetAddress: {
    marginBottom: "1rem",
  },
  section: {
    marginBottom: "2rem",
  },
}))

// COMPONENT FUNCTION
const DirectionsPage = () => {
  // STYLE OBJECT
  const classes = useStyles()

  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      directions: file(relativePath: { eq: "directions.jpg" }) {
        ...BsmFluidImage
      }
      offRoute6: file(relativePath: { eq: "off-route-6.jpg" }) {
        ...BsmFluidImage
      }
    }
  `)

  // COMPONENT
  return (
    <Layout title="Directions">
      <PageTitle title="DIRECTIONS" />

      <ImageBlock>
        <ImageWithCaption
          fluid={data.directions.childImageSharp.fluid}
          caption="Map of roads to Bay Sails Marine"
          displayCaption
        />
      </ImageBlock>

      <div className={classes.section}>
        <Typography variant="h6" component="h2" gutterBottom>
          Address
        </Typography>
        <div className={classes.streetAddress}>
          <Typography>Bays Sails Marine</Typography>
          <Typography>2568 Route 6</Typography>
          <Typography>Wellfleet MA, 02667</Typography>
        </div>
        <Typography>
          <strong>Phone:</strong> 508-349-3840
        </Typography>
        <Typography>
          <strong>Fax:</strong> 508-349-7982
        </Typography>
      </div>

      <div className={classes.section}>
        <Typography variant="h6" component="h2" gutterBottom>
          From the South
        </Typography>
        <Typography paragraph>
          Once you’re on the Cape take Route 6 East to Wellfleet. Bay Sails
          Marine is on the right hand side approximately 12 miles past the
          Orleans rotary. You may see our storage yard on the way, just go
          another 1/2 mile to reach the store and service yard. If you see
          Cumberland Farms and PJ’s you’ve gone to far.
        </Typography>
      </div>

      <div className={classes.section}>
        <Typography variant="h6" component="h2" gutterBottom>
          From the North
        </Typography>
        <Typography paragraph>
          Bay Sails Marine is approximately 14 miles south of Provincetown. Take
          Route 6W to Wellfleet. Bay Sails will be on your left hand side just
          past the Mobil station and Cumberland Farms.
        </Typography>
      </div>

      <div className={classes.section}>
        <Typography variant="h6" component="h2" gutterBottom>
          By Boat
        </Typography>
        <Typography paragraph>
          Bay Sails is located a little more than a mile from{" "}
          <Link href="https://www.wellfleet-ma.gov/harbormaster-marina">
            Wellfleet Harbor
          </Link>
          . From the town pier take Commercial Street to Main Street and turn
          right. Follow Main Street and turn right onto Route 6. Bay Sails
          Marine will be on your left hand side about a quarter mile down the
          road.
        </Typography>
      </div>

      <ImageBlock>
        <ImageWithCaption
          fluid={data.offRoute6.childImageSharp.fluid}
          caption="Off Route 6 in Wellfleet (image &copy;Google)"
          displayCaption
        />
      </ImageBlock>
    </Layout>
  )
}

export default DirectionsPage
